import { useContext, useEffect, useState } from "react";
import HomeBanner from "../Components/HomeBanner/HomeBanner";
// import VideoSection from "../Components/VideoSection/VideoSection";
import GetApiCall from "../Helper/GetApi";
import GetSeoData from "../Helper/GetSeoData";
import PostApiCall from "../Helper/PostApi";

import HomeAbout from "../Components/HomeAbout/HomeAbout";
import TimberBanner from "../Components/NewSectionForFourProducts/Timber";
import { store } from "../Helper/Context/Store";
import
Products from "../Components/AllFourProducts/AllFourProducts";
import TestimonialSection from "../Components/TestimonialSection/TestimonialSection";
import VideoSection from "../Components/VideoSection/VideoSection";
import AllFourProducts from "../Components/AllFourProducts/AllFourProducts";

export default function Home() {
  const [mainBanner, setMainBanner] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  // const [exclusiveOffers, setExclusiveOffers] = useState([]);
  const [parallax1Banner, setParallax1Banner] = useState([]);
  const [parallax2Banner, setParallax2Banner] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true)
  const [websiteTestimonialData, setWebsiteTestimonialData] = useState([]);

  const { setExclusiveOffers } = useContext(store);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);


  useEffect(() => {
    getBanners();
    GetBlogArticle();
    GetTestimonial();
    GetApiCall.getRequest("GetHomepageSetup").then((resultdes) =>
      resultdes.json().then((obj) => {
        // setHomePageSetup(obj.data);
        obj.data
          .filter((value) => value.fld_section_name === "Featured Products")
          .forEach((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              if (results.status === 200 || results.status === 201) {
                results.json().then((data) => {
                  setBestSeller(data.data);
                  setLoading(false)
                });
              }
            });
          });
        // obj.data.filter((value) => value.fld_section_name === "Exclusive Offers").forEach((filtereddata) => {
        obj.data
          .filter((value) => value.fld_section_name === "Featured Products")
          .forEach((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              results.json()?.then((obj) => {
                if (results.status === 200 || results.status === 201) {
                  setExclusiveOffers(obj.data);
                }
              });
            });
          });
      })
    );
  }, []);

  const GetTestimonial = () => {
    PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "*",
      },
      "GetTestimonial"
    ).then((results) => {
      results.json()?.then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setWebsiteTestimonialData(obj.data);
        }
      });
    });
  };
  const GetBlogArticle = () => {
    GetApiCall.getRequest("GetBlogArticle").then((homepagesetupresult) => {
      homepagesetupresult.json().then((obj) => {
        setBlogData(obj.data);
      });
    });
  };
  const getBanners = () => {
    GetApiCall.getRequest("GetBannersV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          var filterBanner = obj1.data.filter(
            (item) => item.location === "Main"
          );
          var Parallax1Banner = obj1.data.filter(
            (item) => item.location === "Parallax"
          );
          var Parallax2Banner = obj1.data.filter(
            (item) => item.location === "Parallax2"
          );
          // var RightMainBanner = obj1.data.filter(
          //   (item) => item.location === "MainRight"
          // );
          // var CatBanner = obj1.data.filter(
          //   (item) => item.location === "CategoryBanner"
          // );
          setMainBanner(filterBanner);
          setParallax1Banner(Parallax1Banner);
          setParallax2Banner(Parallax2Banner);
          setLoading(false)
          // setMainRightBanner(RightMainBanner)
          // setCategoryBanner(CatBanner)
        }
      })
    );
  };

  return (
    <>
      {/* {console.log(exclusiveOffers)} */}
      <GetSeoData type="Home" id={null} />
      {/* <FixedNavbar /> */}
      <HomeBanner banners={mainBanner} loadingState={loading} />
      <AllFourProducts perfumeData={bestSeller} loadingState={loading} />
      <HomeAbout />
      <TestimonialSection />
      {/* <VideoSection /> */}
      {/* <TimberBanner items={bestSeller} /> */}
      {/* <BottomBanner /> */}
      {/* <ExclusiveOffersSection itemsData={bestSeller} /> */}
      {/*<ParallaxSection parallaxData={parallax1Banner} height="30vh" />
      <GirlsPerfume itemsData={exclusiveOffers} />
      <BoysPerfume /> */}
      {/* <HomeAbout /> */}
      {/* <GiftingIdeasSection />
      <BlogsSection blogData={blogData} />
      <FooterPolicySection /> */}
    </>
  );
}
