import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AiFillInstagram } from "react-icons/ai";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaPinterestP,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoCall, IoMail } from "react-icons/io5";
import { Link } from "react-router-dom";
import amex from "../../assets/img/payements-icons/amex.svg";
import bhim from "../../assets/img/payements-icons/bhim.svg";
import gpay from "../../assets/img/payements-icons/gpay.svg";
import mastercard from "../../assets/img/payements-icons/mastercard.svg";
import paytm from "../../assets/img/payements-icons/paytm.svg";
import phonepe from "../../assets/img/payements-icons/phonepe.svg";
import rupay from "../../assets/img/payements-icons/rupay.svg";
import upi from "../../assets/img/payements-icons/upi.svg";
import visa from "../../assets/img/payements-icons/visa.svg";
import { store } from "../../Helper/Context/Store";
import GetApiCall from "../../Helper/GetApi";
import SocialIcons from "../WrapperComponents/SocialIcons";
import "./Footer.css";
import FooterBanner from "../../assets/img/FOOTER.jpg";
import FooterMobileBanner from "../../assets/img/FOOTER-MOBILE-new.jpg";
import FooterLeftIcon from "../../assets/img/footer-left-icon.png";
import FooterRightIcon from "../../assets/img/footer-right-icon.png";
export default function Footer() {
  const { clientData } = useContext(store);
  const [staticPages, setStaticPages] = useState([]);


  useEffect(() => {
    GetApiCall.getRequest("GetStaticPagesList").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setStaticPages(obj1.data);
        }
      })
    );
  }, []);
  return (
    <>
      <footer className="main-footer">
        <div className="container-fluid pt-4 pb-3 position-relative">
          <div className="row">
            <div className="col-md-6">
              <span className="fs-5 mb-0 d-flex flex-column flex-lg-row social-icon-bullet align-items-lg-center">
                <a href={`tel:${clientData[0]?.mobile}`}>
                  <IoCall /> {clientData[0]?.mobile}{" "}
                  <span className="d-none d-lg-inline ms-1">|</span>
                </a>
                <a href={`mailto:${clientData[0]?.email}`} className="ms-lg-2">
                  <IoMail /> {clientData[0]?.email}{" "}
                  <span className="d-none d-lg-inline ms-1">|</span>
                </a>

                {/* Social links */}
                <ul className="footer-sm-icons ms-lg-2">
                  <SocialIcons
                    link={clientData[0]?.facebook}
                    target="_blank"
                    logo={<FaFacebookF />}
                  />
                  <SocialIcons
                    link={clientData[0]?.instagram}
                    target="_blank"
                    logo={<AiFillInstagram />}
                  />
                  <SocialIcons
                    link={clientData[0]?.twitter}
                    target="_blank"
                    logo={<FaXTwitter />}
                  />
                  <SocialIcons
                    link={clientData[0]?.linkedin}
                    target="_blank"
                    logo={<FaLinkedinIn />}
                  />
                  <SocialIcons
                    link={clientData[0]?.pintrest}
                    target="_blank"
                    logo={<FaPinterestP />}
                  />
                  <SocialIcons
                    link={clientData[0]?.youtube}
                    target="_blank"
                    logo={<FaYoutube />}
                  />
                </ul>
              </span>
            </div>
            <div className="col-md-6 d-flex align-items-lg-center justify-content-lg-end">
              <ul className="payments-icons ps-0">
                <li>
                  <img alt="" src={mastercard} />
                </li>
                <li>
                  <img alt="" src={visa} />
                </li>
                <li>
                  <img alt="" src={amex} />
                </li>
                <li>
                  <img alt="" src={bhim} />
                </li>
                <li>
                  <img alt="" src={gpay} />
                </li>

                <li>
                  <img alt="" src={paytm} />
                </li>
                <li>
                  <img alt="" src={phonepe} />
                </li>
                <li>
                  <img alt="" src={rupay} />
                </li>
                <li>
                  <img alt="" src={upi} />
                </li>
              </ul>
            </div>
            <div className="col-12 mt-4 z-1">
              <ul className="d-flex flex-wrap flex-lg-nowrap flex-column flex-lg-row main-nav-menu-links px-0 justify-content-center align-items-lg-center">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/our-story">Our Story</Link>
                </li>
                {staticPages.map((link) => (
                  <li key={link.fld_id}>
                    <Link to={link.fld_route}>{link.fld_page_name}</Link>
                  </li>
                ))}

                <li>
                  <Link to={"/contact-us"}>Contact Us</Link>
                </li>
                <li className="">
                  <Link to={"/faq"}>FAQ</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* </footer>
      <footer className="main-footer"> */}
        <img src={FooterBanner} className="w-100 d-none d-md-block" />
        {/* <img src={FooterMobileBanner} className="w-100 d-block d-md-none" /> */}
        <div className="container-fluid footer-bottom pt-2">
          <div className="footer-icons">
            <img src={FooterRightIcon} className="img-fluid d-block d-md-none" />
            <img src={FooterLeftIcon} className="img-fluid pt-1 d-block d-md-none" />
          </div>
          <div className="row flex-column-reverse flex-lg-row copyright-mobile-space">
            <div className="col-12 col-lg-6 text-lg-start text-center footer-copy-right phone-copr-right-font mobile-bgcolor">
              Powered by{" "}
              <a href="https://bechofy.in/" target="blank">
                {" "}
                Bechofy
              </a>{" "}
              - <br className="d-block d-md-none" /> A Product of
              <a href="https://globaltrendz.com/" target="blank">
                &nbsp; Global Trendz
              </a>
            </div>
            <div className="col-12 col-lg-6 text-lg-end text-center">
              <p className="mb-0 footer-copy-right text-lg-end text-center phone-copr-right-font">
                Copyright &copy; {moment().format("YYYY")} Kaawaii Cosmetic.
                <br className="d-block d-md-none" /> All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
