import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  Nav,
  Navbar, Offcanvas
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AiFillInstagram } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";
import {
  FaFacebookF
} from "react-icons/fa";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { MdShoppingCart } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
// import logo from "../../assets/img/Kaawaii-logo.png";
import { store } from "../../Helper/Context/Store";
import GetApiCall from "../../Helper/GetApi";
import ChangePassword from "../Login/ChangePassword";
import LoginModal from "../Login/LoginModal";
import LogoutModal from "../Login/LogoutModal";
import SocialIcons from "../WrapperComponents/SocialIcons";
import "./MainNavabar.css";
import PostApiCall from "../../Helper/PostApi";


export default function MainNavabar() {
  const history = useHistory();
  // const [] = useState(false)
  const { cart, setcart, setcartamount, logo, loggedIn, clientData, setLoggedIn, setCartItems,
  } = useContext(store);
  const [loginModalStatus, setLoginModalStatus] = useState(false);
  const [modalSide, setModalSide] = useState("");
  const [openChangePwd, setOpenChangePwd] = useState(false);
  const [canLogout, setCanLogout] = useState(false);
  const [Offcanvas1, setOffcanvas1] = useState(false);
  const [show, setShow] = useState(false);
  const handleCloseoffcanvas = () => setOffcanvas1(false);
  const handleShowoffcanvas = () => setOffcanvas1(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [staticPages, setStaticPages] = useState([]);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    getCartdetails();
    GetApiCall.getRequest("GetStaticPagesList").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setStaticPages(obj1.data);
        }
      })
    );
  }, []);

  useEffect(() => {
    getCustomerDetails();
  }, [loggedIn]);

  const getCustomerDetails = () => {
    var loginDetails = JSON.parse(localStorage.getItem("CustomerData"));
    if (loginDetails !== null) {
      setLoggedIn(true);
    }
  };
  const getCartdetails = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          setCartItems(res1.data);
          Notiflix.Loading.remove();
          setcart(res1?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          // setcartamount(res1.data[0]?.fld_cart_amount_preview);
        }
      });
  };
  const onLogout = () => {
    if (localStorage.getItem("CustomerData") !== null) {
      localStorage.removeItem("CustomerData");
      localStorage.removeItem("OrderData");
      setcart(0);
      setcartamount(0);
      setCartItems([]);
      history.push("/");
      setLoggedIn(false);
      setCanLogout(false);
      Notiflix.Loading.remove();
    } else {
      Notiflix.Notify.failure("Something went wrong");
    }
  };

  // login specific functions
  const handleToggleModal = () => {
    setLoginModalStatus(false);
  };
  const handleChangeModalSide = (e) => {
    setModalSide(e);
  };
  const clearItemDetails = () => {
    localStorage.removeItem("itemdetails");
  };
  const handleProfileClick = () => {
    history.push("/customer-profile");
  };
  const changePssword = () => {
    setOpenChangePwd(true);
  };
  // change password specific functions
  const handleTogglePwdModal = () => {
    setOpenChangePwd(false);
  };
  const handleToggleLogout = () => {
    setCanLogout(false);
  };
  const handleOpenCart = () => {
    // if (loggedIn) {
    //   history.push("/cart");
    // } else {
    //   setModalSide("Log");
    //   setLoginModalStatus(true);
    // }
    history.push("/cart");
  };

  const isHomePage = window.location.pathname == "/";
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }
  }, []);
  return (
    <>
      {/* Modals for login, logout and change password */}
      <LoginModal
        isOpen={loginModalStatus}
        handleToggleModal={handleToggleModal}
        modalSide={modalSide}
        handleChangeModalSide={handleChangeModalSide}
      />

      <ChangePassword
        isOpen={openChangePwd}
        handdleToggleModal={handleTogglePwdModal}
      />
      <LogoutModal
        show={canLogout}
        onHide={handleToggleLogout}
        onLogout={onLogout}
      />

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="blurbg">
        <Offcanvas.Header closeButton className="ps-2">
          <Offcanvas.Title>
            <Link to="/" onClick={handleClose}>
              <img alt="" src={logo} className="img-fluid nav-logo" />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0 mobile-nav-drower">
          <Nav className="d-block offcanvas-menu-color ps-0 pe-1">
            <Link
              to={'/'}
              className="nav-link ps-0 pe-1"
              onClick={() => handleClose()}>
              Home
            </Link>
            <Link
              to="#"
              className="nav-link ps-0 pe-1"
              onClick={() => {
                handleOpenCart()
                handleClose()
              }}>
              Cart
            </Link>
            <Link
              to={"/ct/perfumes/1/1"}
              className="nav-link ps-0 pe-1"
              onClick={handleClose}>
              Products
            </Link>
            <Link
              to={"/our-story"}
              className="nav-link ps-0 pe-1"
              onClick={handleClose}>
              Our Story
            </Link>
            {staticPages.map((page) => (
              <li key={page.fld_id} className="nav-link ps-0 pe-1">
                <Link to={page.fld_route} onClick={() => handleClose()} >{page.fld_page_name}</Link>
              </li>
            ))}

            <li className="nav-link ps-0 pe-1">
              <Link to={"/contact-us"} onClick={() => handleClose()}>Contact Us</Link>
            </li>
          </Nav>
          <div className="d-flex contact-links justify-content-start align-items-center my-2 ps-0 pe-1">
            <IoCall className="me-2 fs-5" />
            <a href={`tel:${clientData[0]?.mobile}`}>{clientData[0]?.mobile}</a>
          </div>
          <div className="d-flex contact-links justify-content-start align-items-center mb-3 ps-0 pe-1">
            <IoMdMail className="me-2 fs-5" />
            <a href={`mailto:${clientData[0]?.email}`}>
              {clientData[0]?.email}
            </a>
          </div>
          <h6 className="ps-1 offcanvas-follow-content mt-3 border-top pt-3 fw-bold">
            Follow us on
          </h6>
          <ul className="d-flex contact-links my-2 ps-0 justify-content-start">
            <SocialIcons
              link={clientData[0]?.facebook}
              target="_blank"
              logo={<FaFacebookF />}
            />
            <SocialIcons
              link={clientData[0]?.instagram}
              target="_blank"
              logo={<AiFillInstagram />}
            />
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <Navbar
        expand="lg"
        className={`navbar ${isHomePage
          ? "home-bg px-md-4 px-2 main-navbar pt-4 nav-desk-position w-100 "
          : `inner-page-nav px-md-4 px-2 main-navbar py-3 nav-desk-position w-100  ${scroll ? "bg-theme" : ""}`
          }`}
      >
        <div
          className={`container-fluid ${isHomePage ? "home-bg px-4" : "nav-box-inner-banner pe-lg-4"
            }`}
        >
          <Navbar.Brand href="/" className="d-flex align-items-center">
            <img
              alt=""
              src={logo}
              className={`container-fluid ${isHomePage
                ? "img-fluid nav-logo ps-0"
                : "img-fluid nav-logo ps-0 nav-logo-inner-new"
                }`}
            />
          </Navbar.Brand>

          <ul className="main-nav-right-links contact-links d-flex ms-auto">
            <li className=" desktop-top-link">
              <Dropdown className="login-dropdown d-none d-lg-flex">
                <Dropdown.Toggle
                  // onClick={onProfileClick}
                  className="login-icon"
                  id="dropdown-basic">
                  <BiSolidUser />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {loggedIn === false && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Log");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}>
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Reg");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}>
                        Sign Up
                      </Dropdown.Item>
                    </>
                  )}
                  {loggedIn === true && (
                    <>
                      <Dropdown.Item onClick={handleProfileClick}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={changePssword}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setCanLogout(true)}>
                        Logout
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="login-dropdown d-flex d-lg-none">
                <Dropdown.Toggle
                  // onClick={onProfileClick}
                  className="login-icon"
                  id="dropdown-basic">
                  <BiSolidUser />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {loggedIn === false && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Log");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}>
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Reg");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}>
                        Sign Up
                      </Dropdown.Item>
                    </>
                  )}
                  {loggedIn === true && (
                    <>
                      <Dropdown.Item onClick={handleProfileClick}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={changePssword}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setCanLogout(true)}>
                        Logout
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li
              className="cursor-pointer me-lg-2 desktop-top-link desk-fixed-icons"
              onClick={handleOpenCart}>
              <MdShoppingCart />
              <span className="cart-qut">| &nbsp; {cart} Items</span>
            </li>

            <Button
              className="toggle-btn-offcanvas pe-0 toggle-desk-new"
              onClick={handleShow}>
              <HiMiniBars3CenterLeft className="offcanvas-toggle-new" />
            </Button>
          </ul>
        </div>
      </Navbar>
      {/* For fixed footer */}

      <footer className="footer-bottom-mobile d-none fixed-bottom bg-white">
        <div className="container-fluid py-2 border-bottom border-white">
          <div className="row">
            <div className="col-4 d-flex align-items-center">
              <Button
                className="toggle-btn-offcanvas pe-0"
                onClick={handleShow}>
                <HiMiniBars3CenterLeft className="offcanvas-toggle-new" />
              </Button>
            </div>

            <div
              className="col-4 d-flex align-items-center justify-content-center"
              onClick={handleOpenCart}>
              <MdShoppingCart className="fs-3" />
              {/* <span>{cart} Items</span> */}
            </div>
            <div className="col-4 d-flex align-items-center justify-content-end">
              <Dropdown className="login-dropdown d-none d-lg-flex ps-5">
                <Dropdown.Toggle
                  // onClick={onProfileClick}
                  className="login-icon"
                  id="dropdown-basic">
                  <BiSolidUser />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {loggedIn === false && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Log");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}>
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Reg");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}>
                        Sign Up
                      </Dropdown.Item>
                    </>
                  )}
                  {loggedIn === true && (
                    <>
                      <Dropdown.Item onClick={handleProfileClick}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={changePssword}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setCanLogout(true)}>
                        Logout
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="login-dropdown d-flex d-lg-none">
                <Dropdown.Toggle
                  // onClick={onProfileClick}
                  className="login-icon"
                  id="dropdown-basic">
                  <BiSolidUser className="me-3" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {loggedIn === false && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Log");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}>
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Reg");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}>
                        Sign Up
                      </Dropdown.Item>
                    </>
                  )}
                  {loggedIn === true && (
                    <>
                      <Dropdown.Item onClick={handleProfileClick}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={changePssword}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setCanLogout(true)}>
                        Logout
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
