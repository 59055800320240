import { useEffect, useState } from "react";
import blueBannerDesk from "../../assets/img/ProductsBanner/Innerbanner/BLUE B.jpg";
import blueBannerMobile from "../../assets/img/ProductsBanner/Innerbanner/BLUE B M.jpg";
import orangeBannerDesk from "../../assets/img/ProductsBanner/Innerbanner/ORANGE B.jpg";
import orangeBannerMobile from "../../assets/img/ProductsBanner/Innerbanner/ORANGE B M.jpg";
import pinkBannerDesk from "../../assets/img/ProductsBanner/Innerbanner/PINK B.jpg";
import pinkBannerMobile from "../../assets/img/ProductsBanner/Innerbanner/PINK B M.jpg";
import yellowBannerDesk from "../../assets/img/ProductsBanner/Innerbanner/YELLOW B.jpg";
import yellowBannerMobile from "../../assets/img/ProductsBanner/Innerbanner/YELLOW B M.jpg";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";

export default function Innerbanner({ loadingState, }) {
  const params = useParams();
  const itemNames = params.productname;
  const [selectedBanner, setSelectedBanner] = useState('');
  const [mobileBanner, setMobileBanner] = useState('')

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);


  useEffect(() => {
    switch (itemNames) {
      case "orange-blossom-":
        setSelectedBanner(orangeBannerDesk);
        setMobileBanner(orangeBannerMobile);
        break;

      case "flower-power":
        setSelectedBanner(pinkBannerDesk);
        setMobileBanner(pinkBannerMobile);
        break;

      case "timber-gold":
        setSelectedBanner(yellowBannerDesk);
        setMobileBanner(yellowBannerMobile);
        break;

      case "aqua-blue":
        setSelectedBanner(blueBannerDesk);
        setMobileBanner(blueBannerMobile);
        break;
      default:
        setSelectedBanner(blueBannerDesk);
        setMobileBanner(blueBannerMobile);
        break;
    }
  }, []);

  return (
    <section className="p-0 m-0 mobile-imgae-space">
      <div className="container-fluid p-0 m-0">
        <div className="row mb-2 p-0 m-0">
          {
            loadingState === true ?

              <div className="col-12 p-0 m-0 inner-banner-size">
                <Skeleton.Image className="inner-banner d-none d-md-flex w-100" />
                <Skeleton.Image className="inner-banner d-flex d-md-none w-100" />
              </div>
              :
              <div className="col-12 p-0 m-0 inner-banner-size">
                <img src={selectedBanner} className="inner-banner d-none d-md-flex w-100" />
                <img src={mobileBanner} className="inner-banner d-flex d-md-none w-100" />
              </div>}
        </div>
      </div>
    </section>
  );
}
