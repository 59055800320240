import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import AboutPage from "./Components/AboutPage/AboutPage";
import BlogDeatailPage from "./Components/BlogDeatailPage/BlogDeatailPage";
import NewsBlogsPage from "./Components/BlogsPage/BlogsPage";
import ContactPage from "./Components/ContactPage/ContactPage";
import CustomerProfilePage from "./Components/CustomerProfilePage/CustomerProfilePage";
import ViewAllOrders from "./Components/CustomerProfilePage/ViewAllOrders"
import FaqPage from "./Components/FaqPage/FaqPage";
import Footer from "./Components/Footer/Footer";
import GoToTop from "./Components/GoToTop/GoToTop";
import ViewInvoiceForm from "./Components/Invoice/ViewInvoiceForm";
import FixedNavbar from "./Components/MainNavabar/MainNavabar";
import MobileNavbar from "./Components/MainNavabar/MobileNavbar";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import PaymentFail from "./Components/Payments/PaymentFail";
import PaymentSuccess from "./Components/Payments/PaymentSuccess";
import ProductDetail from "./Components/ProductDetail/ProductDetail";
import ProductListing from "./Components/ProductListing/ProductListing";
import ProtectedRoutes from "./Components/RouteAuth/ProtectedRoutes";
import StaticPage from "./Components/StaticPage/StaticPage";
import Provider from "./Helper/Context/Store";
import GetApiCall from "./Helper/GetApi";
import Cart from "./Pages/Cart/Cart";
import Home from "./Pages/Home";

function App() {
  const [staticPages, setStaticPages] = useState([]);
  useEffect(() => {
    GetApiCall.getRequest("GetStaticPagesList").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setStaticPages(obj1.data);
        }
      })
    );
  }, []);
  return (
    <>
      <Provider>
        <Router>
          <Switch>
            <Route>
              <>
                <FixedNavbar />
                <MobileNavbar />
                <Switch>
                  {staticPages?.map((dat, index) => {
                    return (
                      <Route
                        path={[dat.fld_route]}
                        key={index}
                        component={StaticPage}></Route>
                    );
                  })}
                  <Route exact path={"/"} component={Home} />
                  <Route exact path={"/contact-us"} component={ContactPage} />
                  <Route exact path={"/our-story"} component={AboutPage} />
                  <Route
                    exact
                    path={"/ct/:categoryname/:verticalid/:categoryid"}
                    component={ProductListing}
                  />
                  <Route
                    exact
                    path={"/productdetail/:productname/:variantid"}
                    component={ProductDetail}
                  />
                  <Route
                    exact
                    path={"/cart"}
                    component={Cart}
                  />
                  <ProtectedRoutes
                    exact
                    path={"/customer-profile"}
                    component={CustomerProfilePage}
                    isAuth={
                      localStorage.getItem("CustomerData") == null
                        ? false
                        : true
                    }
                  />
                  <ProtectedRoutes
                    exact
                    path={"/view-all-orders"}
                    component={ViewAllOrders}
                    isAuth={
                      localStorage.getItem("CustomerData") == null
                        ? false
                        : true
                    }
                  />
                  <ProtectedRoutes
                    exact
                    path={"/paymentsuccess/:txnid"}
                    component={PaymentSuccess}
                    isAuth={
                      localStorage.getItem("CustomerData") == null
                        ? false
                        : true
                    }
                  />
                  <Route exact path={"/view-invoice-form/:id/:orderid"} component={ViewInvoiceForm} />
                  <Route exact path={"/faq"} component={FaqPage} />
                  <Route exact path={"/blogs"} component={NewsBlogsPage} />
                  <Route
                    exact
                    path={"/blog-detail"}
                    component={BlogDeatailPage}
                  />
                  <Route exact path={"/*"} component={PageNotFound} />
                </Switch>

                <Footer />
                <GoToTop />
              </>
            </Route>
          </Switch>
        </Router>
      </Provider>
    </>
  );
}

export default App;